import { LoadingCentered } from "@ream/ui";
import React, { useContext } from "react";
import {
  emptyI18n,
  useTranslationsQuery,
  type ReactI18n,
} from "../util/api/translationsApi";

type TranslationContextData = {
  t?: ReactI18n;
  loading: boolean;
};

const TranslationsContext = React.createContext<TranslationContextData>({
  loading: false,
});

export const useTranslations = () => {
  const ctx = useContext(TranslationsContext);
  if (ctx.loading) {
    throw new Error("Translations used before they were loaded.");
  }
  if (!ctx.t) {
    console.error("Oops. We lost our translations.");
    return emptyI18n();
  }
  return ctx.t;
};

export const TranslationsProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { data, isLoading } = useTranslationsQuery();

  return (
    <TranslationsContext.Provider value={{ t: data, loading: isLoading }}>
      {isLoading ? <LoadingCentered /> : children}
    </TranslationsContext.Provider>
  );
};
