"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { AuthProvider } from "../state/AuthContext";
import { TranslationsProvider } from "../state/TranslationsContext";

export const ReamProviders: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <TranslationsProvider>
        <AuthProvider>{children}</AuthProvider>
      </TranslationsProvider>
    </QueryClientProvider>
  );
};
