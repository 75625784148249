import type { AxiosRequestConfig } from "axios";
import type { I18n, TranslateOptions } from "i18n-js";
import type { TranslationRequest, TranslationsExport } from "../../types";

import { useQuery } from "@tanstack/react-query";

import { I18n as RequiredI18n } from "i18n-js/dist/require";

import parse from "html-react-parser";
import { bindAll, isNil, isString } from "lodash-es";
import React from "react";
import reactStringReplace from "react-string-replace";
import { api } from "../api";
import { ApiRoutes } from "../apiRoutes";

const DEFAULT_TRANSLATION_ROOT = "client";
const DEFAULT_TRANSLATION: [string] = [DEFAULT_TRANSLATION_ROOT];

const BIND_FNS = [
  "t",
  "translate",
  "p",
  "pluralize",
  "l",
  "localize",
  "timeAgoInWords",
  "distanceOfTimeInWords",
  "numberToCurrency",
  "numberToDelimited",
  "numberToHuman",
  "numberToHumanSize",
  "numberToPercentage",
  "numberToRounded",
  "strftime",
  "toSentence",
  "toTime",
];

export type ReactI18n = Omit<I18n, "interpolate"> & {
  interpolate: (
    i18n: I18n,
    message: string,
    options: TranslateOptions,
  ) => React.ReactNode;
};

export const emptyI18n = () => new RequiredI18n() as ReactI18n;

const componentInterpolate = (
  i18n: I18n,
  message: string,
  options: TranslateOptions,
) => {
  options = Object.keys(options).reduce((buffer, key) => {
    buffer[i18n.transformKey(key)] = options[key];
    return buffer;
  }, {} as TranslateOptions);

  return reactStringReplace(message, i18n.placeholder, (match, i) => {
    let value: React.ReactNode;
    const placeholder = match as string;
    const name = placeholder.replace(i18n.placeholder, "$1");

    if (!isNil(options[name])) {
      if (React.isValidElement(options[name])) {
        value = options[name];
      } else {
        value = parse(options[name].toString().replace(/\$/gm, "_#$#_"));
      }
    } else if (name in options) {
      value = i18n.nullPlaceholder(i18n, placeholder, message, options);
    } else {
      value = i18n.missingPlaceholder(i18n, placeholder, message, options);
    }

    return <React.Fragment key={i}>{value}</React.Fragment>;
  }).map((n) =>
    isString(n)
      ? (parse(n as string) as React.ReactNode)
      : (n as React.ReactNode),
  );
};

const makeI18nClient = (data: any = {}, locale?: string) => {
  const i18n = new RequiredI18n(data, { locale }) as ReactI18n;

  i18n.interpolate = componentInterpolate;
  i18n.missingTranslation.register("empty", () => "");

  return bindAll(i18n, ...BIND_FNS);
};

export const useTranslationsQuery = (translations?: [string, ...string[]]) => {
  const locale = "en";

  const request: TranslationRequest = {
    locale,
    translations: translations ?? DEFAULT_TRANSLATION,
  };

  return useQuery({
    // TODO: Add deploy identifier or translation hash to key so it refetches when deployed
    queryKey: ["translations", request.locale, request.translations],
    queryFn: ({ signal }) => getTranslations(request, { signal }),
    select: (data) => makeI18nClient(data, locale),
  });
};

const getTranslations = async (
  params: TranslationRequest,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<TranslationsExport>(ApiRoutes.translations(), {
    params: {
      // TODO: Add deploy identifier to query params so it can be cached
      locale: params.locale,
      t: params.translations,
    },
    ...conf,
  });

  return resp.data;
};
