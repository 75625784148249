import(/* webpackMode: "eager", webpackExports: ["ReamProviders"] */ "/app/client/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/client/src/styles/main.scss");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/img/favicon.png");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/img/favicon.svg");
